import React from "react"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import VideoPhoto from "../images/services-photo.jpg"
import Layout from "../components/layout"
import VideoContainer from "../components/VideoContainer"
import SEO from "../components/seo"
import moment from "moment"
import { PageHeaderText } from '../components/common'

const TitleImage = styled(Grid)`
  height: 35vh;
  width: 100%;
  background: linear-gradient(
      180deg,
      rgba(10, 10, 10, 0.61) 35.42%,
      rgba(51, 121, 200, 0.85) 99.48%
    ),
    url(${VideoPhoto});
  background-size: cover;
  display: flex;
`
const VideoInfoContainer = styled(Grid)`
  height: 100%;
  padding: 20px 0;
  background: black;
  box-shadow: inset 4px 4px 7px rgba(0, 0, 0, 0.25);
`
export default ({ pageContext }) => {
  const { playlist } = pageContext
  const playlistItems = playlist.items.sort((item1, item2) =>
    moment(item2.videoPublishedAt).diff(moment(item1.videoPublishedAt))
  )

  return (
    <Layout>
      <SEO title={`${playlist.title} Videos`} />
      <TitleImage item xs={12}>
        <PageHeaderText>{playlist.title}</PageHeaderText>
      </TitleImage>
      <Grid item xs={12}>
        <VideoInfoContainer container>
          {playlistItems &&
            playlistItems.map((item, index) => (
              <Grid key={`video-${index}`} item xs={12} md={4} lg={3}>
                <VideoContainer videoData={item} />
              </Grid>
            ))}
        </VideoInfoContainer>
      </Grid>
    </Layout>
  )
}
